/*
* Login page.
* */
import React, {useState} from 'react';
import './global_theme.css';
import {Link, useNavigate} from "react-router-dom";
import API_ENDPOINTS from './config'
function LoginPage() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState(null);
    /*
    * The below block handles the spinner when a button is clicked
    * */
    const navigate = useNavigate();
    const [isLoginLoading, setLoginLoading] = useState(false);
    const [isContactLoading, setContactLoading] = useState(false);
    /*
    * Button handler for Login - call is made to the RESTful service
    * */
    const handleLoginClick = async  () => {
        setLoginLoading(true);
        await new Promise(resolve => setTimeout(resolve, 2000));
        const requestData = {
            Credentials: {
                Email: email,
                Password: password,
            }
        };

        try {
            const response = await fetch(API_ENDPOINTS.authenticate,
                {
                    method: "POST",
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(requestData),
                });
            const result = await response.json();

            if (result && result.Success) {
                // Store the token upon successful login
                localStorage.setItem('authToken', 'userLoggedIn');
                localStorage.setItem('userEmail', email);  // assuming `email` is the email of the logged-in user
                setMessage("Success! Redirecting in 2 seconds.....");
                setTimeout(() => {
                    navigate('/courses-overview'); // Redirect to overview
                }, 2000);
            } else {
                setMessage(result && result.Message ? result.Message : "Invalid username or password.");
            }
        } catch (error) {
            setMessage("An unknown error occurred.");
        } finally {
            setLoginLoading(false);
        }
    };
    /*
    * Contact button handler
    * */
    const handleContactClick = async  () => {
        setContactLoading(true);
        await new Promise(resolve => setTimeout(resolve, 2000));
        setContactLoading(false);
        navigate("/contact");
    };
    return (
        <div>
            <div className="header-bar"></div>
            <img src="/main-logo.png" alt="Main Logo" />
            <div className="input-group">
                <label htmlFor="email">Username:</label> {/*Username field*/}
                <input type="text" id="email" value={email}
                onChange={(e) => setEmail(e.target.value)}/>
            </div>

            <div className="input-group">
                <label htmlFor="password">Password:</label> {/*Password field*/}
                <input type="password" id="password" value={password}
                onChange={(e) => setPassword(e.target.value)}/>
            </div>
            {/*Login button*/}
            <button className="login-btn" onClick={handleLoginClick} disabled={isLoginLoading}>
                {isLoginLoading ? <div className="spinner"></div> : 'Login'}
            </button>
            {/*Contact button*/}
            <button className="contact-btn" onClick={handleContactClick} disabled={isContactLoading}>
                {isContactLoading ? <div className="spinner"></div> : 'Contact'}
            </button>

            <div className="hyperlink-createAcct"> {/*Create a new account*/}
                Don't have an account? <Link to="/create-account">Create one</Link>
            </div>
            {message && <div className="message">{message}</div>}
        </div>
    );
}

export default LoginPage;