const activity3Content = `
#### Exploring Functions with Examples
Understanding functions deeply often requires seeing them in action. Through practical examples, let's explore various facets of functions in Python.

#### Defining and Calling Functions
Firstly, defining a function involves using the \`def\` keyword followed by a unique function name and parentheses. The code block within every function is indented.

\`\`\`python
def greet():
    print("Hello!")
\`\`\`

Calling a function is as straightforward as writing the function’s name followed by parentheses.

\`\`\`python
greet()  # Output: Hello!
\`\`\`

#### Parameters and Arguments
Parameters are variables listed inside the parentheses in the function definition. When calling a function, you pass values (arguments) into these parameters.

\`\`\`python
def greet(name):
    print(f"Hello, {name}!")
\`\`\`

Call the function with an argument:

\`\`\`python
greet("Alice")  # Output: Hello, Alice!
\`\`\`

#### Return Values
Functions can return values using the \`return\` statement. The function below returns the sum of two numbers:

\`\`\`python
def add(x, y):
    return x + y
\`\`\`

Calling this function:

\`\`\`python
sum = add(3, 5)
print(sum)  # Output: 8
\`\`\`

#### Local and Global Scope
Variables defined inside a function have a local scope and are not accessible outside the function. Conversely, global variables are accessible throughout the code, inside and outside of functions.

#### Lambda Functions
Lambda functions are anonymous functions created with the \`lambda\` keyword. These are often used for short, simple operations:

\`\`\`python
square = lambda x: x * x
print(square(5))  # Output: 25
\`\`\`

#### Built-In Functions
Python comes with a set of built-in functions like \`print()\`, \`len()\`, \`type()\`, and many more. These are readily available for use, and you're encouraged to familiarize yourself with them to write efficient code.
`;
export default activity3Content;