const activity1Content = `
#### Introduction
File handling is fundamental for any programming language, providing a way to store, retrieve, and manipulate data persistently. Python simplifies file handling, making it easy to read and write data to text, binary, and other file formats.

#### Understanding Files in Python
- **What is a File?** 
  - A file is a storage unit where data is stored persistently. Files can be text or binary, with data written in human-readable or byte format.
  
- **File Operations:**
  - Python supports various operations on files, crucial for data processing and management. The primary operations include reading, writing, appending, and closing files.
  
  - **Reading Files:** This operation retrieves data from a file, making it available for the program to process. You can read files line-by-line or in their entirety.
  
  - **Writing Files:** Writing involves sending data from the program to a file. This operation is crucial for data storage, logging, and generating output data.
  
  - **Appending Files:** Appending allows you to add data to an existing file without overwriting its current content.
  
  - **Closing Files:** After performing operations on a file, it’s essential to close it. Closing a file frees up the resources that were tied with the file and is considered a good practice.

- **Opening Files in Python:**
  - The \`open()\` function is the gateway to file operations in Python. It opens a file and returns a file object, which is then used to read or write data.
  
  - Syntax: \`file_object = open('filename', 'mode')\`
  
  - The mode specifies how the file should be opened, and it can be read mode (\`'r'\`), write mode (\`'w'\`), append mode (\`'a'\`), and others.

- **Closing Files:**
  - Once file operations are complete, you must close the file using the \`close()\` method. Properly closing files is crucial to avoid data corruption or loss.
  
  - Syntax: \`file_object.close()\`
`;

export default activity1Content;