import React, {useCallback, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import '../../node_modules/codemirror/lib/codemirror.css';
import 'codemirror/mode/python/python'; // For Python syntax highlighting
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import '../global_theme.css';
import { marked } from 'marked';
import activity1Content from './L_activities/activity1';
import activity2Content from "./L_activities/activity2";
import CodeCheckQuestion from "./CodeCheckQuestion";
import QuestionComponent from "./QuestionComponent";
import conclusion from "./L_activities/conclusion";
import API_ENDPOINTS from '../config'
import {awsCertPrompt} from "./AWSCertPrompt";

const userEmail = localStorage.getItem('userEmail'); // Retrieve the user email **NEW**
// activityGradedStatus is an array specifying which activities are graded
const activityGradedStatus = [false, true, true, true, true, true, true, true, true, true, true, true, true,
true, true, true, true, true, true, true, true, true, true, true, true, false, true, true, true, true, true, false];
const renderers = {
    code: ({ value }) => {
        return <SyntaxHighlighter style={docco} language="python" children={value ?? ''} />;
    },
};
/*
* The below functions are the activities that will comprise the lesson.
* They will render after each click of Next giving the impression of
* a new page loading.
* */
function Activity1({ enableNext }) {

    // Using useEffect to call enableNext when the component is mounted
    useEffect(() => {
        enableNext();
    }, [enableNext]);

    const htmlContent = marked(activity1Content);

    return (
        <div className="markdown-container" dangerouslySetInnerHTML={{ __html: htmlContent }} />
    );
}
function Activity2({ enableNext }) {
    return <QuestionComponent enableNext={enableNext} prompt={awsCertPrompt} />;
}
function Activity3({ enableNext }) {
    return <QuestionComponent enableNext={enableNext} prompt={awsCertPrompt} />;}
function Activity4({ enableNext }) {
    return <QuestionComponent enableNext={enableNext} prompt={awsCertPrompt} />;}
function Activity5({ enableNext }) {
    return <QuestionComponent enableNext={enableNext} prompt={awsCertPrompt} />;}
function Activity6({ enableNext }) {
    return <QuestionComponent enableNext={enableNext} prompt={awsCertPrompt} />;}
function Activity7({ enableNext }) {
    return <QuestionComponent enableNext={enableNext} prompt={awsCertPrompt} />;}
function Activity8({ enableNext }) {
    return <QuestionComponent enableNext={enableNext} prompt={awsCertPrompt} />;}
function Activity9({ enableNext }) {
    return <QuestionComponent enableNext={enableNext} prompt={awsCertPrompt} />;}
function Activity10({ enableNext }) {
    return <QuestionComponent enableNext={enableNext} prompt={awsCertPrompt} />;}
function Activity11({ enableNext }) {
    return <QuestionComponent enableNext={enableNext} prompt={awsCertPrompt} />;}
function Activity12({ enableNext }) {
    return <QuestionComponent enableNext={enableNext} prompt={awsCertPrompt} />;}
function Activity13({ enableNext }) {
    return <QuestionComponent enableNext={enableNext} prompt={awsCertPrompt} />;}
function Activity14({ enableNext }) {
    return <QuestionComponent enableNext={enableNext} prompt={awsCertPrompt} />;}
function Activity15({ enableNext }) {
    return <QuestionComponent enableNext={enableNext} prompt={awsCertPrompt} />;}
function Activity16({ enableNext }) {
    return <QuestionComponent enableNext={enableNext} prompt={awsCertPrompt} />;}
function Activity17({ enableNext }) {
    return <QuestionComponent enableNext={enableNext} prompt={awsCertPrompt} />;}
function Activity18({ enableNext }) {
    return <QuestionComponent enableNext={enableNext} prompt={awsCertPrompt} />;}
function Activity19({ enableNext }) {
    return <QuestionComponent enableNext={enableNext} prompt={awsCertPrompt} />;}
function Activity20({ enableNext }) {
    return <QuestionComponent enableNext={enableNext} prompt={awsCertPrompt} />;}
function Activity21({ enableNext }) {
    return <QuestionComponent enableNext={enableNext} prompt={awsCertPrompt} />;}
function Activity22({ enableNext }) {
    return <QuestionComponent enableNext={enableNext} prompt={awsCertPrompt} />;}
function Activity23({ enableNext }) {
    return <QuestionComponent enableNext={enableNext} prompt={awsCertPrompt} />;}
function Activity24({ enableNext }) {
    return <QuestionComponent enableNext={enableNext} prompt={awsCertPrompt} />;}
function Activity25({ enableNext }) {
    return <QuestionComponent enableNext={enableNext} prompt={awsCertPrompt} />;}

function Activity26({ enableNext }) {

    // Using useEffect to call enableNext when the component is mounted
    useEffect(() => {
        enableNext();
    }, [enableNext]);

    const htmlContent = marked(activity2Content);

    return (
        <div className="markdown-container" dangerouslySetInnerHTML={{ __html: htmlContent }} />
    );
}

function Activity27({ enableNext }) {
    return <CodeCheckQuestion enableNext={enableNext}
                              title="Explain the concept of 'scalability' in the context of AWS cloud services. How does AWS facilitate scalability for its users?"
                              prompt="Please analyze the following response to the question. Provide feedback in a short paragraph to the user's response to the following question: 'Explain the concept of 'scalability' in the context of AWS cloud services. How does AWS facilitate scalability for its users?'"
    />
}
function Activity28({ enableNext }) {
    return <CodeCheckQuestion enableNext={enableNext}
                              title="Describe the shared responsibility model in AWS. What are the responsibilities of AWS and what responsibilities fall to the customer?"
                              prompt="Please analyze the following response to the question. Provide feedback in a short paragraph to the user's response to the following question: 'Describe the shared responsibility model in AWS. What are the responsibilities of AWS and what responsibilities fall to the customer?'"
    />
}
function Activity29({ enableNext }) {
    return <CodeCheckQuestion enableNext={enableNext}
                              title="What is Amazon S3 and what are its key features? Provide an example of a use case where Amazon S3 would be an appropriate solution."
                              prompt="Please analyze the following response to the question. Provide feedback in a short paragraph to the user's response to the following question: 'What is Amazon S3 and what are its key features? Provide an example of a use case where Amazon S3 would be an appropriate solution.'"
    />
}
function Activity30({ enableNext }) {
    return <CodeCheckQuestion enableNext={enableNext}
                              title="Explain what an Amazon VPC (Virtual Private Cloud) is and its primary use case. How does VPC contribute to a customer's network security on AWS?"
                              prompt="Please analyze the following response to the question. Provide feedback in a short paragraph to the user's response to the following question: 'Explain what an Amazon VPC (Virtual Private Cloud) is and its primary use case. How does VPC contribute to a customer's network security on AWS?'"
    />
}
function Activity31({ enableNext }) {
    return <CodeCheckQuestion enableNext={enableNext}
                              title="Describe the concept of 'elasticity' in AWS and how it differs from scalability. Provide an example of an AWS service that offers elasticity."
                              prompt="Please analyze the following response to the question. Provide feedback in a short paragraph to the user's response to the following question: 'Describe the concept of 'elasticity' in AWS and how it differs from scalability. Provide an example of an AWS service that offers elasticity.'"
    />
}

function Activity32({ enableNext }) {

    // Using useEffect to call enableNext when the component is mounted
    useEffect(() => {
        enableNext();
    }, [enableNext]);

    const htmlContent = marked(conclusion);

    return (
        <div className="markdown-container" dangerouslySetInnerHTML={{ __html: htmlContent }} />
    );
}

const activities = [Activity1, Activity2, Activity3, Activity4, Activity5, Activity6, Activity7, Activity8,
    Activity9, Activity10, Activity11, Activity12, Activity13, Activity14, Activity15, Activity16, Activity17,
    Activity18, Activity19, Activity20, Activity21, Activity22, Activity23, Activity24, Activity25, Activity26, Activity27,
    Activity28, Activity29, Activity30, Activity31, Activity32/* ... These activities comprise the
     various parts of the lesson, such as readings, McQs, and coding tasks... */];

function AwsPractice() {
    const [currPage, setCurrPage] = useState(1); // Starting page number incrementor
    const [isNextLoading, setNextLoading] = useState(false);
    const [isNextEnabled, setIsNextEnabled] = useState(false); // New state to manage the Next button enabling
    const navigate = useNavigate();
    const [score, setScore] = useState(0); // To keep track of score
    const [userEmail, setUserEmail] = useState(localStorage.getItem('userEmail')); // **NEW** for grade passback
    const enableNext = useCallback(() => setIsNextEnabled(true), [])
    const currentActivityIsGraded = activityGradedStatus[currPage - 1];
    /***
     * The below is a NEW function for grade passback
     ***/
    const sendGradePassback = async (email, score) => {
        try {
            const response = await fetch(API_ENDPOINTS.gradePassback, {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ Email: email, LessonName: "AWS Cloud Practitioner Practice", Grade: score }),
            });

            const data = await response.json();
            // Handle the response data as needed
            console.log(data);
        } catch (error) {
            console.error('Error sending grade passback:', error);
        }
    };
    const goToNextPage = async () => {
        if (isNextEnabled) {
            if (currentActivityIsGraded) {
                setScore(prevScore => prevScore + 1); // Score increment on press of Next
            }
            setNextLoading(true);
            await new Promise(resolve => setTimeout(resolve, 2000));
            setNextLoading(false);
            if (currPage < activities.length) {
                setCurrPage(prevPage => prevPage + 1);
                setIsNextEnabled(false); // Reset the Next button enabling for the new page
            } else {
                await sendGradePassback(userEmail, score); //**NEW** code for grade passback
                navigate("/awsccp-overview");
            }
        }
    };

    const CurrentActivity = useCallback(() => {
        const ActivityComponent = activities[currPage - 1];
        if (ActivityComponent) {
            return (
                <ActivityComponent enableNext={enableNext} />
            );
        }
        return <div>Activity not found</div>;
    }, [currPage, enableNext]);

    return (
        <div className="headerFooterContainer">
            <h2 style={{color: '#008cba' }}>AWS Certified Cloud Practitioner Exam Practice</h2>
            <CurrentActivity />
            <br/>
            <div>
                <h3 className="score">Score: {score}</h3>
            </div>
            <br/>
            <button className="contact-btn"
                    onClick={() => goToNextPage()}
                    disabled={!isNextEnabled || isNextLoading}>
                {isNextLoading ? <div className="spinner"></div> : 'Next'}
            </button>
            <br/>
            <br/>
        </div>
    );
}
export default AwsPractice;