import React, {useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import './global_theme.css';

function PythonOverview() {
    const [isBackLoading, setBackLoading] = useState(false);
    const navigate = useNavigate();

    const handleBackClick = async  () => {
        setBackLoading(true);
        await new Promise(resolve => setTimeout(resolve, 2000));
        setBackLoading(false);
        navigate("/courses-overview");
    }

    return (
        <div className="course-overview">
            <div className="header-spacer"></div>
            <div className="header-overview">Introduction to Python</div>
            {/*Individual lesson links*/}
            <ul>
                <dt><Link to="/py-lesson1">1. Variables, Expressions, and Statements</Link></dt>
                <dt><Link to="/py-lesson2">2. Conditional Execution</Link></dt>
                <dt><Link to="/py-lesson3">3. Functions</Link></dt>
                <dt><Link to="/py-lesson4">4. Iteration</Link></dt>
                <dt><Link to="/py-lesson5">5. Strings</Link></dt>
                <dt><Link to="/py-lesson6">6. Files</Link></dt>
                <dt><Link to="/py-lesson7">7. Lists, Dictionaries, and Tuples</Link></dt>
                <dt><Link to="/py-lesson8">8. Regular Expressions</Link></dt>
            </ul>
            <button className="other-btn" onClick={handleBackClick}>
                {isBackLoading ? <div className="spinner"></div> : 'Back'}
            </button>
        </div>
    )
}
export default PythonOverview;