import React, { useState, useEffect } from 'react';

const DropTarget = ({ items, handleDrop, setFeedback, enableNext }) => {
    const [droppedItems, setDroppedItems] = useState([]);
    const correctOrder = ['2', '1', '5', '0', '4', '3'];

    useEffect(() => {
        if (droppedItems.length === correctOrder.length) {
            const isCorrect = droppedItems.every((item, index) => item.id === correctOrder[index]);
            if (isCorrect) {
                setFeedback({ message: 'Correct!', type: 'success' });
                enableNext(); // Enable the "Next" button
            } else {
                setFeedback({ message: 'Incorrect. Try again.', type: 'error' });
                setTimeout(() => setDroppedItems([]), 1000); // Reset dropped items after 1 second
            }
        } else {
            setFeedback(null);
        }
    }, [droppedItems, setFeedback, enableNext]);

    const checkOrder = () => {
        if (droppedItems.length === correctOrder.length) {
            const isCorrect = droppedItems.every((item, index) => item.id === correctOrder[index]);
            if (isCorrect) {
                setFeedback('Correct!');
            } else {
                setFeedback('Incorrect. Try again.');
                setDroppedItems([]); // Reset dropped items if incorrect
            }
        } else {
            setFeedback(''); // Ensure feedback is cleared if not full
        }
    };

    const drop = e => {
        e.preventDefault();
        const itemId = e.dataTransfer.getData('itemId');
        const item = items.find(item => item.id === itemId);
        if (!droppedItems.some(dItem => dItem.id === item.id)) { // Prevent dropping the same item twice
            setDroppedItems([...droppedItems, item]);
            handleDrop(item);
        }
    };

    const allowDrop = e => {
        e.preventDefault();
    };
    // Rendering the container. Styling included.
    return (
        <div onDrop={drop} onDragOver={allowDrop} style={{ minHeight: '50px', border: '2px dashed grey', marginTop: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {droppedItems.map(item => (
                <div key={item.id} style={{ margin: '5px' }}>{item.content}</div>
            ))}
        </div>
    );
}

export default DropTarget;

