const activity1Content = `
# Introduction

The following questions are multiple-choice and focus on key concepts of AWS cloud services, including:

 1. **Cloud architecture**
 2. **Security**
 3. **Technology**
 4. **Billing**
`;

export default activity1Content;
