import React, {useState} from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import './global_theme.css';
import {useNavigate} from "react-router-dom";
import API_ENDPOINTS from './config'
function CreateAccountPage() {
    const [captchaValue, setCaptchaValue] = useState(null); // This state is for ReCAPTCHA
    // Captcha handler
    const handleRecaptchaChange = value => {
        setCaptchaValue(value);
    }
    /*
    * Ensure that all fields have values so empty strings aren't saved in the users' database.
    * */
    const allFieldsFilled = () => {
        return firstName && lastName && email && password;
    }

    /*
     * Constants to help with the account creation process by storing input
     */
    const navigate = useNavigate();
    const [message, setMessage] = useState(null);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    /*
     * The below block handles the spinner when a button is click.
     * */
    const [isSubmitLoading, setSubmitLoading] = useState(false);
    const [isBackLoading, setBackLoading] = useState(false);
    /*
    * Back button handler
    * */
    const handleBackClick = async  () => {
        setBackLoading(true);
        await new Promise(resolve => setTimeout(resolve, 2000));
        setBackLoading(false);
        navigate("/login");
    }
    /*
    * Submit button handler for account creation - RESTful service is utilized to create the account
    * */
    const handleSubmitClick = async  () => {
        setSubmitLoading(true);
        await new Promise(resolve => setTimeout(resolve, 2000));
        /*
        * The below code ensures that the captcha checkbox is checked before allowing submission
        * */
        if (!captchaValue) {
            setMessage("Please complete the CAPTCHA!");
            setSubmitLoading(false);
            return;
        }

        const requestData = {
            CreateAccountRequest: {
                FirstName: firstName,
                LastName: lastName,
                Email: email,
                Password: password,
            },
        };

        try {
            const response = await fetch(API_ENDPOINTS.createAccount,
                {
                    method: 'POST',
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(requestData),
                });
            const result = await response.json();

            if (result && result.Success) {
                setMessage("Your account has been successfully created!");
                // Wait for 2 seconds (2000 milliseconds) before redirecting
                setTimeout(() => {
                    navigate('/login'); // Redirect back to login page
                }, 2000);
            } else {
                setMessage(result && result.Message ? result.Message : "An unknown error occurred.");
            }

        } catch (error) {
            setMessage("An error occurred while trying to create the account.");
        } finally {
            setSubmitLoading(false);
        }
    };
    return (
     <div>
         <div className="header-bar"></div>
         <img src="/main-logo.png" alt="Main Logo" />
         <div className="input-group">
             <label htmlFor="firstName">First Name:</label> {/*First name field*/}
             <input type="text" id="firstName" value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}/>
         </div>
         <div className="input-group">
             <label htmlFor="lastName">Last Name:</label> {/*Last name field*/}
             <input type="text" id="lastName" value={lastName}
                    onChange={(e) => setLastName(e.target.value)}/>
         </div>
         <div className="input-group">
             <label htmlFor="email">Email Address:</label> {/*Email address field*/}
             <input type="email" id="email" value={email}
                    onChange={(e) => setEmail(e.target.value)}/>
         </div>
         <div className="input-group">
             <label htmlFor="password">Password:</label> {/*Email address field*/}
             <input type="password" id="password" value={password}
                    onChange={(e) => setPassword(e.target.value)}/>
         </div>
         <ReCAPTCHA
             sitekey="6LfBBNknAAAAAFhXwL_-l8c8rv7jyianp4Xq8DZr"
             onChange={handleRecaptchaChange}
         />
         <button className="other-btn" onClick={handleSubmitClick} disabled={!allFieldsFilled()}
                 title={!allFieldsFilled() ? "All fields are required." : ""}>
             {isSubmitLoading ? <div className="spinner"></div> : 'Submit'}
         </button>
         <button className="other-btn" onClick={handleBackClick}>
             {isBackLoading ? <div className="spinner"></div> : 'Back'}
         </button>
         {message && <div className="message">{message}</div>}
     </div>
    );
}

export default CreateAccountPage;