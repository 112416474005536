const activity3Content = `
#### **Practical File Handling Techniques:**
Understanding practical file-handling techniques in Python is crucial as it allows developers to read from and write data to files, interact with the file system, and implement features like logging and data persistence in applications.

##### **1. Using Context Managers:**
Context managers (using the \`with\` statement) are ideal for file handling as they automatically manage resources for you. They ensure that the file is closed properly after its suite finishes.

- Example:
  \`\`\`python
  with open('example.txt', 'r') as file:
      for line in file:
          print(line, end='')
  \`\`\`

##### **2. Iterating Through Files:**
Files are iterable in Python, which means you can loop over a file object to get its lines. Iterating through files line by line is memory efficient.

- Example:
  \`\`\`python
  with open('example.txt', 'r') as file:
      for line in file:
          print(line.strip())
  \`\`\`

##### **3. Working with JSON Data:**
Python’s \`json\` module can parse JSON from strings or files. The module can also convert Python dictionaries into JSON strings.

- Example:
  \`\`\`python
  import json
  data = {'name': 'John', 'age': 30, 'city': 'New York'}
  json_str = json.dumps(data)
  \`\`\`

##### **4. Error Handling:**
Implement error-handling mechanisms using \`try\`...\`except\` blocks to handle potential errors, like file not found, permission issues, or I/O errors.

- Example:
  \`\`\`python
  try:
      with open('nonexistent_file.txt', 'r') as file:
          content = file.read()
  except FileNotFoundError:
      print('File not found!')
  \`\`\`

#### **File Handling Examples:**

##### **Example 1: Writing to Files:**
Below is an example of how to write to files, demonstrating both writing and appending modes.

- Code:
  \`\`\`python
  with open('example.txt', 'w') as file:
      file.write('Hello, Python!')
  with open('example.txt', 'a') as file:
      file.write('\\nAppending a second line.')
  \`\`\`

##### **Example 2: Reading from Files:**
This example shows how to read the entire content of a file and how to read a file line by line.

- Code:
  \`\`\`python
  with open('example.txt', 'r') as file:
      content = file.read()
      print(content)
  \`\`\`

##### **Example 3: Working with CSV Files:**
Python has a built-in CSV module that supports reading from and writing to CSV files.

- Code:
  \`\`\`python
  import csv
  with open('people.csv', mode ='w', newline='') as file:
      writer = csv.writer(file)
      writer.writerow(['Name', 'Age', 'City'])
      writer.writerow(['John', '30', 'New York'])
      writer.writerow(['Marie', '22', 'Boston'])
  \`\`\`

### Summary of File Handling Techniques and Examples:
Practical file-handling techniques are fundamental for efficiently working with data in Python applications. Whether you are reading or writing, handling errors, or working with different file formats, understanding these techniques and learning through practical examples provides a strong foundation for effective file operations in Python. With this knowledge, you are well-equipped to handle various file I/O tasks in your Python programming journey, enhancing the range and depth of problems you can solve.
`;

export default activity3Content;