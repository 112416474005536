import React, {useCallback, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import '../../node_modules/codemirror/lib/codemirror.css';
import 'codemirror/mode/python/python'; // For Python syntax highlighting
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import '../global_theme.css';
import { marked } from 'marked';
import activity1Content from './L1_activities/activity1';
import activity2Content from "./L1_activities/activity2";
import activity4Content from "./L1_activities/activity4";
import activity6Content from "./L1_activities/activity6";
import CodeCheckQuestion from "./CodeCheckQuestion";
import QuestionComponent from "./QuestionComponent";
import conclusion from "./L1_activities/conclusion";
import DndActivity from "./L1_activities/DndActivity";
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import API_ENDPOINTS from '../config'

const userEmail = localStorage.getItem('userEmail'); // Retrieve the user email **NEW**
// activityGradedStatus is an array specifying which activities are graded
const activityGradedStatus = [false, false, true, true, false, true, false, true, true, true, true, false];
const renderers = {
    code: ({ value }) => {
        return <SyntaxHighlighter style={docco} language="python" children={value ?? ''} />;
    },
};
/*
* The below functions are the activities that will comprise the lesson.
* They will render after each click of Next giving the impression of
* a new page loading.
* */
function Activity1({ enableNext }) {

    // Using useEffect to call enableNext when the component is mounted
    useEffect(() => {
        enableNext();
    }, [enableNext]);

    const htmlContent = marked(activity1Content);

    return (
        <div className="markdown-container" dangerouslySetInnerHTML={{ __html: htmlContent }} />
    );
}
function Activity2({ enableNext }) {

    // Using useEffect to call enableNext when the component is mounted
    useEffect(() => {
        enableNext();
    }, [enableNext]);

    const htmlContent = marked(activity2Content);

    return (
        <div className="markdown-container" dangerouslySetInnerHTML={{ __html: htmlContent }} />
    );
}
function Activity3({ enableNext }) {
    return <QuestionComponent enableNext={enableNext} prompt="Provide a unique plain text multiple-choice question on Variables in Python. The question should be theoretical or conceptual, with no direct code snippets in the question or the answers. Provide four answer choices, only one of which should be correct. Provide the answer and a concise explanation as to why it's correct." />;
}
function Activity4({ enableNext }){
    return (
        <DndProvider backend={HTML5Backend}>
            <DndActivity enableNext={enableNext} />
        </DndProvider>
    );
}
function Activity5({ enableNext }) {

    // Using useEffect to call enableNext when the component is mounted
    useEffect(() => {
        enableNext();
    }, [enableNext]);

    const htmlContent = marked(activity4Content);

    return (
        <div className="markdown-container" dangerouslySetInnerHTML={{ __html: htmlContent }} />
    );
}
function Activity6({ enableNext }) {
    return <QuestionComponent enableNext={enableNext} prompt="Provide unique a plain text multiple-choice question on Expressions in Python. The question should be theoretical or conceptual, with no direct code snippets in the question or the answers. Provide four answer choices, only one of which should be correct. Provide the answer and a concise explanation as to why it's correct." />;
}
function Activity7({ enableNext }) {

    // Using useEffect to call enableNext when the component is mounted
    useEffect(() => {
        enableNext();
    }, [enableNext]);

    const htmlContent = marked(activity6Content);

    return (
        <div className="markdown-container" dangerouslySetInnerHTML={{ __html: htmlContent }} />
    );
}
function Activity8({ enableNext }) {
    return <QuestionComponent enableNext={enableNext} prompt="Provide unique a plain text multiple-choice question on the topic of Statements (Statements are lines of code that instruct Python to perform specific actions or operations.) in Python. The question should be theoretical or conceptual, with no direct code snippets in the question or the answers. Provide four answer choices, only one of which should be correct. Provide the answer and a concise explanation as to why it's correct." />;
}
function Activity9({ enableNext }) {
    return <CodeCheckQuestion enableNext={enableNext}
        title="Define a global variable, and a local variable in a function called my_func()"
        prompt="Please assess the code to ensure it adheres to these instructions and provide a very brief explanation: 'Define a global variable, and a local variable in a function called my_func()'"
    />
}
function Activity10({ enableNext }) {
    return <CodeCheckQuestion enableNext={enableNext}
        title="Create a function named calculate() that takes two integers as parameters, x and y. Inside the function, create an expression to calculate the sum of x and y squared ((x + y) ** 2) and return the result. Also, print the type of the result using the type() function. Call the function with x = 4 and y = 3."
        prompt="Please assess the code to ensure it adheres to these instructions and provide a very brief explanation: 'Create a function named calculate() that takes two integers as parameters, x and y. Inside the function, create an expression to calculate the sum of x and y squared ((x + y) ** 2) and return the result. Also, print the type of the result using the type() function. Call the function with x = 4 and y = 3.'"
    />
}
function Activity11({ enableNext }) {
    return <CodeCheckQuestion enableNext={enableNext}
        title="Create a function named print_elements() that takes a list of integers. Inside the function, use a for loop to iterate through the list and an if statement to check if the number is greater than 5. If it is, print the number. Finally, include a comment explaining what the function does. Call the function using the list [2, 4, 6, 8, 10]."
        prompt="Please assess the code to ensure it adheres to these instructions and provide a very brief explanation: 'Create a function named print_elements() that takes a list of integers. Inside the function, use a for loop to iterate through the list and an if statement to check if the number is greater than 5. If it is, print the number. Finally, include a comment explaining what the function does. Call the function using the list [2, 4, 6, 8, 10].'"
    />
}
function Activity12({ enableNext }) {

    // Using useEffect to call enableNext when the component is mounted
    useEffect(() => {
        enableNext();
    }, [enableNext]);

    const htmlContent = marked(conclusion);

    return (
        <div className="markdown-container" dangerouslySetInnerHTML={{ __html: htmlContent }} />
    );
}
const activities = [Activity1, Activity2, Activity3, Activity4, Activity5, Activity6, Activity7,
    Activity8, Activity9, Activity10, Activity11, Activity12/* ... These activities comprise the
     various parts of the lesson, such as readings, McQs, and coding tasks... */];
function VarsExpStat() {
    const [currPage, setCurrPage] = useState(1); // Starting page number incrementor
    const [isNextLoading, setNextLoading] = useState(false);
    const [isNextEnabled, setIsNextEnabled] = useState(false); // New state to manage the Next button enabling
    const navigate = useNavigate();
    const [score, setScore] = useState(0); // To keep track of score
    const [userEmail, setUserEmail] = useState(localStorage.getItem('userEmail')); // **NEW** for grade passback
    const enableNext = useCallback(() => setIsNextEnabled(true), [])
    const currentActivityIsGraded = activityGradedStatus[currPage - 1];

    /***
     * The below is a NEW function for grade passback
     ***/
    const sendGradePassback = async (email, score) => {
        try {
            const response = await fetch(API_ENDPOINTS.gradePassback, {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ Email: email, LessonName: "Variables, Expressions, and Statements", Grade: score }),
            });

            const data = await response.json();
            // Handle the response data as needed
            console.log(data);
        } catch (error) {
            console.error('Error sending grade passback:', error);
        }
    };
    const goToNextPage = async () => {
        if (isNextEnabled) {
            if (currentActivityIsGraded) {
                setScore(prevScore => prevScore + 1); // Score increment on press of Next
            }
            setNextLoading(true);
            await new Promise(resolve => setTimeout(resolve, 2000));
            setNextLoading(false);
            if (currPage < activities.length) {
                setCurrPage(prevPage => prevPage + 1);
                setIsNextEnabled(false); // Reset the Next button enabling for the new page
            } else {
                await sendGradePassback(userEmail, score); //**NEW** code for grade passback
                navigate("/python-overview");
            }
        }
    };

    const CurrentActivity = useCallback(() => {
        const ActivityComponent = activities[currPage - 1];
        if (ActivityComponent) {
            return (
                <ActivityComponent enableNext={enableNext} />
            );
        }
        return <div>Activity not found</div>;
    }, [currPage, enableNext]);

    return (
        <div className="headerFooterContainer">
            <h2 style={{color: '#008cba' }}>Lesson 1 - Variables, Expressions, and Statements</h2>
            <CurrentActivity />
            <br/>
            <div>
                <h3 className="score">Score: {score}</h3>
            </div>
            <br/>
            <button className="contact-btn"
                    onClick={() => goToNextPage()}
                    disabled={!isNextEnabled || isNextLoading}>
                {isNextLoading ? <div className="spinner"></div> : 'Next'}
            </button>
            <br/>
            <br/>
        </div>
    );
}
export default VarsExpStat;