/*
 * The below function will assess free response answers for logical and syntax correctness.
 * **/
import {useEffect, useRef, useState} from "react";
import CodeMirror from "codemirror";
import ReactMarkdown from 'react-markdown';
import API_ENDPOINTS from '../config'
function CodeCheckQuestion({title, prompt, enableNext}) {
    const [inputFeedback, setInputFeedback] = useState('');
    const codeMirrorContainer = useRef(null);
    const codeMirrorInstance = useRef(null);
    const [code, setCode] = useState(''); // to capture the code content
    const [isSubmitLoading, setSubmitLoading] = useState(false);
    // The below is used to set up codemirror's styling based on the mode.
    useEffect(() => {
        if (codeMirrorContainer.current) {
            codeMirrorInstance.current = CodeMirror(codeMirrorContainer.current, {
                mode: 'python',
                lineNumbers: true,
                indentUnit: 4,
                smartIndent: true,
            });
            codeMirrorInstance.current.on('change', editor => {
                setCode(editor.getValue());
            });
        }
    }, []);
    /***
     * The submit handler will send a prompt and the code to assess to the specified endpoint.
     ***/
    const handleSubmit = async () => {
        setSubmitLoading(true);
        await new Promise(resolve => setTimeout(resolve, 1000));
        //const encodedCode = btoa(code);
        const response = await fetch(API_ENDPOINTS.AltInCheck, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({Code: code, Prompt: prompt, ApiKey: process.env.REACT_APP_TEST_STR})
        });

        const result = await response.json();
        setInputFeedback(result.Feedback);
        setSubmitLoading(false);

        enableNext();  // Calling enableNext directly without checking isSuccess
    };
    //const titleParts = title.split('. ');  // Splitting by sentences
    return (
        <div>
            <h3 className="gptFeedbackContainer">
                {title}
            </h3>
            <div ref={codeMirrorContainer} style={{ position: 'relative', height: '200px', border: '1px solid #ccc', overflow: 'auto' }}></div>
            <br/>
            <div>
                <button
                    style={{backgroundColor: '#008cba', color: 'white', borderRadius: '4px', border: 'none', padding: '5px 15px', width: '100px'}}
                    onClick={handleSubmit}
                    disabled={isSubmitLoading}
                >
                    {isSubmitLoading ? <div className="spinner"></div> : 'Submit'}
                </button>
                {isSubmitLoading && (
                    <span style={{ marginLeft: '10px'}}>Please wait, this could take a minute.</span>
                )}
            </div>
            <br/>
            <div className="gptFeedbackContainer">
                <ReactMarkdown>
                    {inputFeedback}
                </ReactMarkdown>
            </div>
        </div>
    );
}

export default CodeCheckQuestion;