const activity1Content = `
#### Introduction:
Python offers various data structures to provide ways of storing and manipulating data. Among these structures, Lists, Dictionaries, and Tuples are fundamental, offering flexibility and functionality in handling collections of data.

#### Lists in Python:
- **Definition:** Lists are ordered sequences of elements, enclosed within square brackets \`[]\`. 
- **Versatility:** They can contain elements of mixed data types, including other lists, allowing nested structures.
- **Mutability:** Lists are mutable, meaning the elements within can be changed, added, or removed dynamically.
  
  \`\`\`python
  example_list = [1, 'Python', 3.6, [1, 2, 3]]
  \`\`\`

- **Operations:** You can perform various operations on lists, such as indexing, slicing, and concatenating.

#### Dictionaries in Python:
- **Definition:** Dictionaries are unordered sets of key-value pairs enclosed within curly braces \`{}\`.
- **Unique Keys:** Each key in a dictionary must be unique, and keys can be of any hashable data type.
- **Dynamic:** Dictionaries are mutable, allowing you to add, remove, and change items efficiently.
  
  \`\`\`python
  example_dict = {'language': 'Python', 'version': 3.9, 'is_fun': True}
  \`\`\`

- **Access Pattern:** Values are accessed using their corresponding keys, providing a straightforward way to organize and retrieve data.

#### Tuples in Python:
- **Definition:** Tuples are ordered sequences of elements, similar to lists but enclosed within parentheses \`()\`.
- **Immutability:** Unlike lists, tuples are immutable, meaning their elements cannot be changed after initialization.
  
  \`\`\`python
  example_tuple = (1, 'Python', 3.6)
  \`\`\`

- **Usage:** Tuples are often used for read-only data, and since they are immutable, they can be used as keys in dictionaries.

Understanding Lists, Dictionaries, and Tuples is foundational for anyone looking to master Python, as these structures are extensively used for data manipulation and storage. Each of them offers unique features and functionalities suited for various tasks and challenges in programming. Whether you need ordered data, key-value mappings, or immutable sequences, Python’s core data structures have got you covered.
`;

export default activity1Content;