import React, { useState } from 'react';
import DraggableItem from './DraggableItem';
import DropTarget from './DropTarget';
// Below array contains draggable item names and types. The ids are used to set the correct order.
const items = [
    { id: '0', content: '"Hello "', type: 'string' },
    { id: '1', content: '+', type: 'operator' },
    { id: '2', content: 'greeting', type: 'variable' },
    { id: '3', content: '=', type: 'whitespace' },
    { id: '4', content: '"World"', type: 'string' },
    { id: '5', content: '*', type: 'invalid' },
];

const DndActivity = ({ enableNext }) => {
    const [arrangement, setArrangement] = useState([]);
    const [feedback, setFeedback] = useState(''); // Added feedback state

    const handleDrop = (item) => {
        setArrangement([...arrangement, item]);
    };

    return (
        <div>
            <div style={styles.instructionContainer}>
                <p style={styles.instructionText}>
                    <strong>Instructions:</strong> Drag and drop the code snippets into the correct order to create a concatenated string.
                    Incorrect orders will be reset.
                </p>
            </div>
            <div style={{ display: 'flex' }}>
                {items.map((item) => (
                    <DraggableItem key={item.id} content={item.content} id={item.id} />
                ))}
            </div>
            <DropTarget items={items} handleDrop={handleDrop} setFeedback={setFeedback} enableNext={enableNext} />
            {feedback && (
                <div style={{...styles.feedbackText, backgroundColor: feedback.type === 'success' ? 'lightgreen' : 'lightcoral' }}>
                    {feedback.message}
                </div>
            )}
        </div>
    );
};

const styles = {
    instructionContainer: {
        backgroundColor: '#f8d7da',
        borderColor: '#f5c6cb',
        padding: '10px',
        marginBottom: '15px',
        border: '1px solid transparent',
        borderRadius: '4px',
    },
    instructionText: {
        marginBottom: '0',
        color: '#721c24',
    },
    feedbackText: {
        margin: '10px 0',
        padding: '10px',
        borderRadius: '4px',
        textAlign: 'center',
        fontSize: '16px',
        fontWeight: 'bold',
    }
}

export default DndActivity;
