const activity2Content = `
#### Reading Files in Python

- **\`read()\` Method:**
  - The \`read()\` method reads the content of a file as a string. Optionally, you can specify the number of characters to read.
  - Example:
    \`\`\`python
    file = open('example.txt', 'r')
    content = file.read()
    print(content)
    file.close()
    \`\`\`
- **\`readline()\` & \`readlines()\` Methods:**
  - \`readline()\` reads a single line from the file.
  - \`readlines()\` reads all lines and returns them as a list.
  - These methods are useful for iterating through the file content line by line.

#### Writing Files

- **Writing Text:**
  - The \`write()\` method writes a string to the file.
  - If the file doesn't exist, Python will create it for you.
  - Example:
    \`\`\`python
    file = open('example.txt', 'w')
    file.write('Hello, World!')
    file.close()
    \`\`\`
- **Writing Multiple Lines:**
  - You can write multiple lines using \`writelines()\` or by passing a list of strings to the \`write()\` method.

#### Appending to Files

- To add content to an existing file without overwriting, use the append mode (\`'a'\`).
- Example:
  \`\`\`python
  file = open('example.txt', 'a')
  file.write('\\nAppending text.')
  file.close()
  \`\`\`

#### Working with Binary Files

- **Reading and Writing Binary Data:**
  - Use mode \`'rb'\` or \`'wb'\` for reading and writing binary files respectively.
  - This mode is essential when dealing with non-text files like images or executables.

#### File Positions

- The \`tell()\` method returns the current position of the file cursor.
- \`seek(offset)\` is used to change the file cursor position. \`offset\` is the position you want the cursor to move to.

#### Context Manager: \`with\` statement

- The \`with\` statement simplifies file handling by automatically closing the file once the block of code is executed, even if errors occur within the block.
- Example:
  \`\`\`python
  with open('example.txt', 'r') as file:
      content = file.read()
      print(content)
  \`\`\`

### Importance of Proper File Handling
Proper file handling is essential for data integrity and efficient resource use. Automatically closing files using the \`with\` statement or explicitly using \`close()\` helps prevent data corruption and system resource leakage. Always handle files in the appropriate mode to avoid unintentional data overwrites or losses. For binary data, always use binary modes to ensure accurate reading and writing operations. 
`;

export default activity2Content;