const activity3Content = `
### Overview of Conditional Execution in Python

Conditional execution refers to the process where specific blocks of code are executed based on whether a given condition is \`True\` or \`False\`. In Python, conditional execution is primarily handled using the \`if\`, \`elif\`, and \`else\` statements.

### The \`if\` Statement

The \`if\` statement evaluates a given condition. If the condition is \`True\`, the block of code indented under \`if\` is executed.

\`\`\`python
x = 10
if x > 5:
    print("x is greater than 5")  # This line will be executed
\`\`\`

### The \`elif\` Statement

\`elif\` (short for "else if") allows for additional conditions to be checked if the preceding \`if\` (or \`elif\`) statement’s condition is \`False\`.

\`\`\`python
x = 3
if x > 5:
    print("x is greater than 5")
elif x > 2:
    print("x is greater than 2 but not greater than 5")  # This line will be executed
\`\`\`

### The \`else\` Statement

\`else\` captures all other cases where the preceding \`if\` (and \`elif\` if present) conditions are \`False\`.

\`\`\`python
x = 1
if x > 5:
    print("x is greater than 5")
else:
    print("x is not greater than 5")  # This line will be executed
\`\`\`

### Combining Conditions

You can use logical operators (\`and\`, \`or\`, \`not\`) to combine multiple conditions.

\`\`\`python
x = 7
if x > 5 and x < 10:
    print("x is between 5 and 10")  # This line will be executed
\`\`\`

### Nested Conditional Statements

Conditional statements can be nested within one another, providing more flexibility.

\`\`\`python
x = 15
if x > 10:
    if x < 20:
        print("x is between 10 and 20")  # This line will be executed
\`\`\`

### Inline \`if\` (Ternary Operator)

Python supports single-line \`if\` statements, often known as the ternary operator.

\`\`\`python
x = 10
message = "Even" if x % 2 == 0 else "Odd"
print(message)  # Outputs "Even"
\`\`\`

### Short-circuit Evaluation

Python supports short-circuit evaluation. If the first condition in an \`and\` operation is \`False\`, or the first in an \`or\` operation is \`True\`, Python does not evaluate the rest of the conditions.

\`\`\`python
x = False
y = True
if x and y:  # y will not be evaluated because x is False
    print("Both are True")
\`\`\`

### Using \`if\` with Non-boolean Conditions

Non-boolean conditions in \`if\` statements are converted to Boolean. Common truthy values include non-zero numbers and non-empty strings; common falsy values are \`0\`, \`""\`, \`None\`, and empty collections like \`[]\`.

\`\`\`python
name = "Alice"
if name:  # Since name is non-empty, this condition is True
    print("Hello,", name)  # Outputs "Hello, Alice"
\`\`\`

`;

export default activity3Content;