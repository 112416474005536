import React from 'react';

const DraggableItem = ({ content, id }) => {
    return (
        <div
            draggable
            onDragStart={(e) => e.dataTransfer.setData('itemId', id)}
            style={{
                margin: '5px',
                padding: '8px',
                border: '1px solid black',
                cursor: 'grab',
                backgroundColor: 'lightgray',
                textAlign: 'center',
                color: 'black', // ensures text is visible against background
                width: '60px', // provides enough width
                height: '30px', // and height
                display: 'flex', // uses flexbox to center the text
                alignItems: 'center',
                justifyContent: 'center'
            }}
        >
            {content}
        </div>
    );
};

export default DraggableItem;
