const activity1Content = `
#### Introduction
- Strings are one of the most fundamental data types in Python, crucial for various applications. As you engage with textual data frequently, understanding strings is vital for Python programming.

#### What Are Strings?
- In Python, strings are sequences of characters enclosed within single (' '), double (" "), or triple (''' ''' or """ """) quotes. Each character in a string has a unique index, starting from 0, which allows for easy access and manipulation.
- Strings are **immutable**. Once a string is created, you cannot change its content. Any operation that seems to modify a string will instead create a new one.

#### Creating Strings
- To create a string, you can use single, double, or triple quotes. The choice depends on the string content and your preference.
  \`\`\`python
  str1 = 'Hello, World!' # Using single quotes
  str2 = "Python is powerful!" # Using double quotes
  str3 = '''Triple quoted strings can
  span multiple lines''' # Using triple quotes
  \`\`\`
- Triple quotes are often used for multiline strings or docstrings (the string literals used for documenting code).

#### Accessing and Slicing Strings
- Use indexing to access individual characters in a string. Python supports both positive and negative indexing.
  \`\`\`python
  greeting = "Hello"
  print(greeting[0])   # Outputs 'H'
  print(greeting[-1])  # Outputs 'o'
  \`\`\`
- Slicing allows you to obtain a substring from a string. The syntax is \`string[start:stop:step]\`.
  \`\`\`python
  text = "Python Programming"
  print(text[7:15])  # Outputs 'Programm'
  \`\`\`

#### Common String Methods
- Python provides numerous methods for string manipulation. Below are some commonly used ones:
  - \`lower()\`: Converts all characters in the string to lowercase.
  - \`upper()\`: Converts all characters to uppercase.
  - \`len()\`: Returns the length of the string.
  - \`split()\`: Splits the string into a list of substrings based on a specified delimiter.
  - \`replace()\`: Replaces a specified substring with another substring.
  \`\`\`python
  sentence = "Python is Fun"
  print(sentence.lower())      # python is fun
  print(sentence.upper())      # PYTHON IS FUN
  print(len(sentence))         # 14
  print(sentence.split())      # ['Python', 'is', 'Fun']
  print(sentence.replace('Fun', 'Awesome'))  # Python is Awesome
`;
export default activity1Content;