import React, {useState} from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import './global_theme.css';
import {useNavigate} from "react-router-dom";
import API_ENDPOINTS from './config'
function ContactPage() {
    const [isBackLoading, setBackLoading] = useState(false);
    const [isSubmitLoading, setSubmitLoading] = useState(false);
    const [message, setMessage] = useState("");
    const navigate = useNavigate();
    // States for input
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [comment, setComment] = useState('');
    const [captchaValue, setCaptchaValue] = useState(null); // This state is for ReCAPTCHA
    // Captcha handler
    const handleRecaptchaChange = value => {
        setCaptchaValue(value);
    }
    const allFieldsFilled = () => {
        return firstName && lastName && email && comment;
    }
    /*
    * Back button handler
    * */
    const handleBackClick = async  () => {
        setBackLoading(true);
        await new Promise(resolve => setTimeout(resolve, 2000));
        setBackLoading(false);
        navigate("/login");
    }
    /***
     * The below handler will submit the message if the captchaValue returns true.
     * It will then send the message and contact info to the REST service endpoint in a stringified JSON format.
     * From there, the message will once again be relayed to the Amazon SES endpoint.
     ***/
    const handleSubmitClick = async  () => {
        setSubmitLoading(true);
        await new Promise(resolve => setTimeout(resolve, 2000));

        if (!captchaValue) {
            setMessage("Please complete the CAPTCHA!");
            setSubmitLoading(false);
            return;
        }

        const requestData = {
            request: {
                FirstName: firstName,
                LastName: lastName,
                Email: email,
                Comment: comment,
            },
        };

        try {
            const response = await fetch(API_ENDPOINTS.SendEmail,{
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(requestData)
            });
            const responseData = await response.json();
            if (responseData.SendEmailResult) {
                setMessage("Message sent successfully!");
                setTimeout(() => {
                    navigate('/login'); // Redirect back to login page
                }, 2000);
            }
            else {
                setMessage("Failed to send message. An unknown error occurred.");
            }
        } catch (error) {
            setMessage("Unknown error; please try again later.");
        }
        setSubmitLoading(false);
    };
    return (
        <div>
            <div className="header-bar"></div>
            <img src="/main-logo.png" alt="Main Logo" />
            <div className="input-group">
                <label htmlFor="text">First Name:</label>
                <input type="text" id="firstName" value={firstName} onChange={e => setFirstName(e.target.value)}></input>
            </div>
            <div className="input-group">
                <label htmlFor="text">Last Name:</label>
                <input type="text" id="lastName" value={lastName} onChange={e => setLastName(e.target.value)}></input>
            </div>
            <div className="input-group">
                <label htmlFor="email">Email:</label>
                <input type="email" id="email" value={email} onChange={e => setEmail(e.target.value)}></input>
            </div>
            <div className="input-group">
                <textarea className="textarea" id="comment" value={comment} onChange={e => setComment(e.target.value)}
                    placeholder={"Please leave your comment...."}></textarea>
            </div>
            <ReCAPTCHA
                sitekey="6LfBBNknAAAAAFhXwL_-l8c8rv7jyianp4Xq8DZr"
                onChange={handleRecaptchaChange}
            />
            <button className="other-btn" onClick={handleSubmitClick} disabled={!allFieldsFilled()}
                    title={!allFieldsFilled() ? "All fields are required." : ""}>
                {isSubmitLoading ? <div className="spinner"></div> : 'Submit'}
            </button>
            <button className="other-btn" onClick={handleBackClick}>
                {isBackLoading ? <div className="spinner"></div> : 'Back'}
            </button>
            {message && <div className="message-box">{message}</div>}
        </div>
    );
}

export default ContactPage;