const activity3Content = `
#### Exploring Iteration with Examples:

**Defining and Using For Loops:**
In Python, \`for\` loops offer a convenient way to iterate over items of any sequence (like a list or a string). When implementing a \`for\` loop, Python iterates over each item in the sequence, executing the loop’s body for each.

\`\`\`python
for i in range(5):
    print(i)  # This will print numbers from 0 to 4
\`\`\`

**Using While Loops:**
\`while\` loops are used for scenarios where the number of iterations is unknown. The loop continues to execute as long as the specified condition is true.

\`\`\`python
count = 0
while count < 5:
    print(count)  # This will also print numbers from 0 to 4
    count += 1
\`\`\`

**Nested Loops:**
Python supports nesting loops within loops. Nested loops can be used to iterate over multi-dimensional data structures, like lists of lists or matrices.

\`\`\`python
for i in range(3):
    for j in range(2):
        print(f"i: {i}, j: {j}")  # Demonstrates a nested loop in action
\`\`\`

**Break and Continue:**
These two keywords offer additional control over the flow of loops. \`break\` terminates the loop prematurely while \`continue\` skips the rest of the current iteration and proceeds to the next one.

\`\`\`python
for num in range(5):
    if num == 3:
        break  # This will terminate the loop when num equals 3
    print(num)
\`\`\`

#### Working with Iterators:

**Understanding Iterators:**
Iterators represent a stream of data, allowing you to iterate over its items one at a time. In Python, objects like lists, tuples, and strings are iterable, and you can get their iterator by using the \`iter()\` function.

\`\`\`python
my_list = [1, 2, 3]
my_iter = iter(my_list)
print(next(my_iter))  # Output: 1
print(next(my_iter))  # Output: 2
\`\`\`

#### Using List Comprehensions:

**Crafting Concise Loops:**
List comprehensions provide a concise way to create lists using a single line of code. They offer a syntactic advantage for simpler and cleaner code.

\`\`\`python
squares = [x*x for x in range(5)]  # Creates a list of squares from 0 to 4
print(squares)  # Output: [0, 1, 4, 9, 16]
\`\`\`
`;

export default activity3Content;