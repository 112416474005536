import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './global_theme.css';
import API_ENDPOINTS from './config'
function ScoringOverview() {
    const [scores, setScores] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const email = localStorage.getItem('userEmail');
    const [isBackLoading, setBackLoading] = useState(false);

    useEffect(() => {
        if (!email) {
            // If there is no email in localStorage, navigate to login or other appropriate page
            navigate('/login');
            return;
        }

        async function fetchScores() {
            try {
                const response = await fetch(`${API_ENDPOINTS.userScores}?email=${encodeURIComponent(email)}`, {
                    method: 'GET', mode: 'cors'
                });
                if (!response.ok) {
                    throw new Error('No grades recorded for this user.');
                }
                const data = await response.json();
                setScores(data); // Update state with the fetched scores
            } catch (error) {
                setError(error.message); // Set error state
            } finally {
                setIsLoading(false); // Set loading state to false once data is fetched or an error occurs
            }
        }

        fetchScores();
    }, [email, navigate]); // Dependencies array includes email and navigate

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }
    /***
     * Converts the date returned by the service to a human-readable format
     * **/
    function formatDate(jsonDate) {
        const matches = /\/Date\((\d+)([+-]\d+)\)\//.exec(jsonDate);
        if (matches) {
            const ms = matches[1];
            const date = new Date(parseInt(ms, 10));
            return date.toLocaleDateString("en-US");
        }
        return "Invalid Date";
    }
    const handleBackClick = async  () => {
        setBackLoading(true);
        await new Promise(resolve => setTimeout(resolve, 2000));
        setBackLoading(false);
        navigate("/courses-overview");
    }

    return (
        <div>
            <h1>Your Scores</h1>
            <table>
                <thead>
                <tr>
                    <th>Lesson</th>
                    <th>Score</th>
                    <th>Date</th>
                </tr>
                </thead>
                <tbody>
                {scores.map((score, index) => (
                    <tr key={index}>
                        <td>{score.LessonName}</td>
                        <td>{score.Score}</td>
                        <td>{formatDate(score.DateTimeRecorded)}</td>
                    </tr>
                ))}
                </tbody>
            </table>
            <br/>
            <button className="other-btn" onClick={handleBackClick}>
                {isBackLoading ? <div className="spinner"></div> : 'Back'}
            </button>
        </div>
    );
}

export default ScoringOverview;
