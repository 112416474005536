/**
 * The below will generate multiple choice questions using the gpt-3.5 model through a RESTful API
 * **/
import React, {useEffect, useState} from "react";
import API_ENDPOINTS from '../config'
function QuestionComponent({ prompt, enableNext }) {
    const [questionData, setQuestionData] = useState(null);
    const [selectedOption, setSelectedOption] = useState(null);
    const [isSubmitLoading, setSubmitLoading] = useState(false);
    const [isReloadLoading, setReloadLoading] = useState(false);
    const [feedback, setFeedback] = useState({ message: '', type: '' });
    useEffect(() => {
        const data = {
            Prompt: prompt, // Prompt to send to the endpoint.
            ApiKey: process.env.REACT_APP_TEST_STR
        };
        // REST service endpoint
        fetch(API_ENDPOINTS.CGAlt, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(response => response.json())
            .then(data => {
                setQuestionData(data); // Sets the question and answer sets.
            })
            .catch(error => {
                console.error('An error occurred fetching the question.', error);
            });
    }, []);
    const reloadQuestion = async () => { // This function will resend the prompt to the endpoint to generate a new question and answer set.
        setReloadLoading(true);
        const data = {
            Prompt: prompt,
            ApiKey: process.env.REACT_APP_TEST_STR
        };
        // Resend the prompt to the endpoint.
        try {
            const response = await fetch(API_ENDPOINTS.CGAlt, {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });

            const newQuestionData = await response.json();
            setQuestionData(newQuestionData);
        } catch (error) {
            console.error('An error occurred fetching the question.', error);
        } finally {
            setReloadLoading(false);
        }
    };
    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };
    /***
     * The below handler will check the selected user answer against the correct answer sent back in the endpoint response.
     ***/
    const handleSubmit = async () => {
        setSubmitLoading(true);
        await new Promise(resolve => setTimeout(resolve, 1000));
        // The selectedOption should contain the letter of the choice (e.g., "A", "B", "C", "D")
        const selectedChoiceLetter = selectedOption?.charAt(0); // Get the letter of the selected choice

        // Compare it with the first letter of the CorrectAnswer from the backend
        const correctChoiceLetter = questionData.CorrectAnswer?.charAt(0);

        // Check if the selected choice's letter matches the correct answer's letter
        if (selectedChoiceLetter === correctChoiceLetter) {
            const explanation = questionData.CorrectAnswerExplanation || 'Explanation not provided.';
            setFeedback({
                message: `Correct Answer! ${explanation}`, // Include the explanation in the feedback message
                type: 'correct'
            });
            enableNext(); // Call enableNext when the answer is correct
        } else {
            setFeedback({
                message: 'Incorrect Answer. Please try again',
                type: 'incorrect'
            });
        }
        setSubmitLoading(false);
    };

    if (!questionData) return <div>Loading question...</div>;

    // Split the question at any newline characters.
    const [mainQuestion, ...additionalContent] = questionData.Question.split('\n');

    return (
        <div className="contentContainer">
            <div className="questionContainer">
                {/* Display the main question */}
                <p>{mainQuestion}</p>

                {/* Display any additional expressions or code snippets */}
                {additionalContent.map((content, index) => (
                    <textarea
                        key={index}
                        value={content}
                        readOnly
                        rows="4"
                        cols="50"
                    />
                ))}
            </div>

            <div className="choicesContainer">
                {questionData.Choices.map((choice, index) => (
                    <div key={index}>
                        <label>
                            <input
                                type="radio"
                                value={choice}
                                checked={selectedOption === choice}
                                onChange={handleOptionChange}
                            />
                            {choice}
                        </label>
                    </div>
                ))}
                <br/>
                <div className="buttons">
                    <button style={{backgroundColor: '#008cba', color: 'white', borderRadius: '4px', border: 'none', padding: '5px 15px', width: '100px'}} onClick={handleSubmit} disabled={isSubmitLoading}>
                        {isSubmitLoading ? <div className="spinner"></div>: 'Submit'}
                    </button>
                    <button style={{backgroundColor: '#008cba', color: 'white', borderRadius: '4px', border: 'none', padding: '5px 15px', width: '100px'}} onClick={reloadQuestion} disabled={isReloadLoading}>
                        {isReloadLoading ? <div className="spinner"></div>: 'Reload Question'}
                    </button>
                </div>
                <br/>
                {feedback.message && <div className={`feedback ${feedback.type}`}>{feedback.message}</div>}
            </div>
            <div className="gptContainer">
                <p>Note: The questions presented are dynamically generated using GPT (Generative Pre-trained Transformer) technology.
                    While this method produces diverse and on-the-fly questions, there might occasionally be some inconsistencies or unexpected outputs.
                    If you encounter a question that doesn't seem right, please use the 'Reload Question' feature. We appreciate your understanding!</p>
            </div>
        </div>
    );
}
export default QuestionComponent;