const activity3Content = `
#### Exploring Strings with Examples

##### Defining and Using Strings:
- Strings can be defined using single, double, or triple quotes. Each type of quote serves a purpose, and understanding when to use each can help handle strings containing quote characters or spanning multiple lines efficiently.

    \`\`\`python
    single_quoted = 'Hello, World!'
    double_quoted = "Hello, World!"
    triple_quoted = """Hello,
    World!"""
    \`\`\`

##### String Indexing and Slicing:
- Each character in a string can be accessed using its index. Python also supports negative indexing.

    \`\`\`python
    word = "Python"
    print(word[0])  # Outputs: 'P'
    print(word[-1]) # Outputs: 'n'
    \`\`\`

- Slicing allows you to extract a substring from a string:

    \`\`\`python
    print(word[0:2])  # Outputs: 'Py'
    \`\`\`

##### String Methods:
- Python strings come with built-in methods for common operations:

    \`\`\`python
    sentence = "hello world"
    print(sentence.capitalize())  # Outputs: 'Hello world'
    \`\`\`

##### String Formatting:
- Python provides several ways to format strings, embedding variable values and expressions within string literals.

    \`\`\`python
    name = "Alice"
    print(f"Hello, {name}!")  # Outputs: 'Hello, Alice!'
    \`\`\`

##### Handling Escape Characters:
- Escape characters like \`\\\\n\` and \`\\\\t\` represent newline and tab, respectively. To include a literal backslash, use \`\\\\\\\\\`.

    \`\`\`python
    print("Line 1\\\\nLine 2")  # Outputs two lines
    \`\`\`

#### String Concatenation and Repetition:
- Strings can be concatenated using \`+\` and repeated using \`*\`.

    \`\`\`python
    greeting = "Hello" + " " + "World"  # Concatenation
    separator = "-" * 10  # Repetition
    \`\`\`

#### Immutable Nature of Strings:
- Once a string is created, it cannot be changed. Any operation that seems to modify a string creates a new string instead.

    \`\`\`python
    original = "hello"
    modified = original.upper()  # Does not modify original
    \`\`\`

#### Using Strings in Conditions:
- Strings can be used in comparison operations, which is particularly useful in conditional statements.

    \`\`\`python
    if name.lower() == "alice":
        print("Name is Alice")
    \`\`\`
`;
export default activity3Content;
