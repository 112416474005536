import React, {useCallback, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import '../../node_modules/codemirror/lib/codemirror.css';
import 'codemirror/mode/python/python'; // For Python syntax highlighting
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import '../global_theme.css';
import { marked } from 'marked';
import CodeCheckQuestion from "./CodeCheckQuestion";
import QuestionComponent from "./QuestionComponent";
import conclusion from "./L8_activities/conclusion";
import activity1Content from "./L8_activities/activity1";
import activity2Content from "./L8_activities/activity2";
import activity3Content from "./L8_activities/activity3";
import activity4Content from "./L8_activities/activity4";
import API_ENDPOINTS from '../config'

const userEmail = localStorage.getItem('userEmail'); // Retrieve the user email **NEW**
// activityGradedStatus is an array specifying which activities are graded
const activityGradedStatus = [false, false, false, false, true, true, true, true, true, true, false];
const renderers = {
    code: ({ value }) => {
        return <SyntaxHighlighter style={docco} language="python" children={value ?? ''} />;
    },
};
/*
* The below functions are the activities that will comprise the lesson.
* They will render after each click of Next giving the impression of
* a new page loading.
* */
function Activity1({ enableNext }) {

    // Using useEffect to call enableNext when the component is mounted
    useEffect(() => {
        enableNext();
    }, [enableNext]);

    const htmlContent = marked(activity1Content);

    return (
        <div className="markdown-container" dangerouslySetInnerHTML={{ __html: htmlContent }} />
    );
}
function Activity2({ enableNext }) {

    // Using useEffect to call enableNext when the component is mounted
    useEffect(() => {
        enableNext();
    }, [enableNext]);

    const htmlContent = marked(activity2Content);

    return (
        <div className="markdown-container" dangerouslySetInnerHTML={{ __html: htmlContent }} />
    );
}
function Activity3({ enableNext }) {

    // Using useEffect to call enableNext when the component is mounted
    useEffect(() => {
        enableNext();
    }, [enableNext]);

    const htmlContent = marked(activity3Content);

    return (
        <div className="markdown-container" dangerouslySetInnerHTML={{ __html: htmlContent }} />
    );
}
function Activity4({ enableNext }) {

    // Using useEffect to call enableNext when the component is mounted
    useEffect(() => {
        enableNext();
    }, [enableNext]);

    const htmlContent = marked(activity4Content);

    return (
        <div className="markdown-container" dangerouslySetInnerHTML={{ __html: htmlContent }} />
    );
}
function Activity5({ enableNext }) {
    return <QuestionComponent enableNext={enableNext} prompt="Provide a unique plain text multiple-choice question on 'Regular Expressions' in Python, focusing on different aspects of 'Defining and Using Regular Expressions'. The question should be theoretical or conceptual, with no direct code snippets in the question. Provide four answer choices, only one of which should be correct. Provide the answer and a concise explanation as to why it's correct."/>;
}
function Activity6({ enableNext }) {
    return <QuestionComponent enableNext={enableNext} prompt="Provide a unique plain text multiple-choice question on 'Regular Expressions' in Python, focusing on different aspects of 'Case Matching'. The question should be theoretical or conceptual, with no direct code snippets in the question. Provide four answer choices, only one of which should be correct. Provide the answer and a concise explanation as to why it's correct."/>;
}
function Activity7({ enableNext }) {
    return <QuestionComponent enableNext={enableNext} prompt="Provide a unique plain text multiple-choice question on 'Regular Expressions' in Python, focusing on different aspects of 'Search and Match Functions'. The question should be theoretical or conceptual, with no direct code snippets in the question. Provide four answer choices, only one of which should be correct. Provide the answer and a concise explanation as to why it's correct."/>;
}
function Activity8({ enableNext }) {
    return <CodeCheckQuestion enableNext={enableNext}
        title="Write a Python script that utilizes Regular Expressions to extract all the email addresses from the given text string (text = 'Please contact us at contact@mywebsite.com for further information. You can also reach out to support@mywebsite.com for technical assistance.') and stores them in a list. Ensure your code is capable of extracting emails accurately and efficiently."
        prompt="Please assess the Python code to ensure it adheres to these instructions and provide a very brief explanation: 'Write a Python script that utilizes Regular Expressions to extract all the email addresses from the given text string (text = 'Please contact us at contact@mywebsite.com for further information. You can also reach out to support@mywebsite.com for technical assistance.') and stores them in a list. Ensure your code is capable of extracting emails accurately and efficiently.'"
    />
}
function Activity9({ enableNext }) {
    return <CodeCheckQuestion enableNext={enableNext}
        title="Write a Python script that uses Regular Expressions to validate if a given string is a valid IP address. The IP address is considered valid if it follows the standard IPv4 dot-decimal notation, i.e., four numbers separated by dots, each ranging from 0 to 255. Ex: ip_address = '192.168.1.1'"
        prompt="Please assess the Python code to ensure it adheres to these instructions and provide a very brief explanation: 'Write a Python script that uses Regular Expressions to validate if a given string is a valid IP address. The IP address is considered valid if it follows the standard IPv4 dot-decimal notation, i.e., four numbers separated by dots, each ranging from 0 to 255. Ex: ip_address = '192.168.1.1''"
    />
}
function Activity10({ enableNext }) {
    return <CodeCheckQuestion enableNext={enableNext}
        title="Write a Python script that uses Regular Expressions to check if a given string is a valid date in the format 'dd/mm/yyyy'. You may assume all inputs are strings. If the string is a valid date, print 'Valid date'. Otherwise, print 'Invalid date'."
        prompt="Please assess the Python code to ensure it adheres to these instructions and provide a very brief explanation: 'Write a Python script that uses Regular Expressions to check if a given string is a valid date in the format 'dd/mm/yyyy'. You may assume all inputs are strings. If the string is a valid date, print 'Valid date'. Otherwise, print 'Invalid date'.'"
    />
}
function Activity11({ enableNext }) {

    // Using useEffect to call enableNext when the component is mounted
    useEffect(() => {
        enableNext();
    }, [enableNext]);

    const htmlContent = marked(conclusion);

    return (
        <div className="markdown-container" dangerouslySetInnerHTML={{ __html: htmlContent }} />
    );
}
const activities = [Activity1, Activity2, Activity3, Activity4, Activity5, Activity6, Activity7,
    Activity8, Activity9, Activity10, Activity11/* ... These activities comprise the
     various parts of the lesson, such as readings, McQs, and coding tasks... */];
function RegEx() {
    const [currPage, setCurrPage] = useState(1); // Starting page number incrementor
    const [isNextLoading, setNextLoading] = useState(false);
    const [isNextEnabled, setIsNextEnabled] = useState(false); // New state to manage the Next button enabling
    const navigate = useNavigate();
    const [score, setScore] = useState(0); // To keep track of score
    const [userEmail, setUserEmail] = useState(localStorage.getItem('userEmail')); // **NEW** for grade passback
    // Function to handle enabling the "Next" button
    const enableNext = useCallback(() => setIsNextEnabled(true), []);
    const currentActivityIsGraded = activityGradedStatus[currPage - 1];
    /***
     * The below is a NEW function for grade passback
     ***/
    const sendGradePassback = async (email, score) => {
        try {
            const response = await fetch(API_ENDPOINTS.gradePassback, {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ Email: email, LessonName: "Regular Expressions", Grade: score }),
            });

            const data = await response.json();
            // Handle the response data as needed
            console.log(data);
        } catch (error) {
            console.error('Error sending grade passback:', error);
        }
    };
    const goToNextPage = async () => {
        if (isNextEnabled) {
            if (currentActivityIsGraded) {
                setScore(prevScore => prevScore + 1); // Score increment on press of Next
            }
            setNextLoading(true);
            await new Promise(resolve => setTimeout(resolve, 2000));
            setNextLoading(false);
            if (currPage < activities.length) {
                setCurrPage(prevPage => prevPage + 1);
                setIsNextEnabled(false); // Reset the Next button enabling for the new page
            } else {
                await sendGradePassback(userEmail, score); //**NEW** code for grade passback
                navigate("/python-overview");
            }
        }
    };

    const CurrentActivity = useCallback(() => {
        const ActivityComponent = activities[currPage - 1];
        if (ActivityComponent) {
            return (
                <ActivityComponent enableNext={enableNext} />
            );
        }
        return <div>Activity not found</div>;
    }, [currPage, enableNext]);

    return (
        <div className="headerFooterContainer">
            <h2 style={{color: '#008cba' }}>Lesson 8 - Regular Expressions</h2>
            <CurrentActivity />
            <br/>
            <div>
                <h3 className="score">Score: {score}</h3>
            </div>
            <br/>
            <button className="contact-btn"
                    onClick={() => goToNextPage()}
                    disabled={!isNextEnabled || isNextLoading}>
                {isNextLoading ? <div className="spinner"></div> : 'Next'}
            </button>
            <br/>
            <br/>
        </div>
    );
}
export default RegEx;