const activity3Content = `
#### Exploring Regular Expressions with Examples
- **Defining and Using Regular Expressions:**
  - To define a regular expression, you use the \`re.compile()\` function, passing in the pattern as a string. Then, using this compiled object, various operations such as search, match, findall can be performed.
  - Example:
    \`\`\`python
    import re
    pattern = re.compile(r'\\d+')  # matches one or more digits
    result = pattern.findall('hello 123 world 456')
    print(result)  # Output: ['123', '456']
    \`\`\`
  
- **Search and Match Functions:**
  - \`re.search()\` scans through the string, looking for any location where the RE matches. \`re.match()\` checks for a match only at the beginning of the string.
  - Example:
    \`\`\`python
    text = "Python is fun"
    match = re.search(r'fun', text)
    if match:
        print("Pattern found inside the string")
    else:
        print("Pattern not found")
    \`\`\`
  
- **Grouping in Regular Expressions:**
  - Grouping is done with parentheses. It helps in extracting individual parts of the string.
  - Example:
    \`\`\`python
    pattern = re.compile(r'(\\d+).(\\d+)')
    matched = pattern.match('123.456')
    if matched:
        print(matched.groups())  # Output: ('123', '456')
    \`\`\`

- **Special Sequences and Characters:**
  - \`\\d\`, \`\\w\`, \`\\s\` represent digit, word, whitespace respectively; their uppercase variants represent the inverse.
  - Example:
    \`\`\`python
    result = re.findall(r'\\d', 'abc123')
    print(result)  # Output: ['1', '2', '3']
    \`\`\`

- **Substitution and Replacement:**
  - \`re.sub()\` method can be used to replace substrings.
  - Example:
    \`\`\`python
    text = "hello world"
    new_text = re.sub(r'world', 'Python', text)
    print(new_text)  # Output: hello Python
    \`\`\`

- **Flags in Regular Expressions:**
  - Flags modify how the regular expressions are interpreted. For example, \`re.I\` or \`re.IGNORECASE\` ignores case sensitivity.
  - Example:
    \`\`\`python
    result = re.match(r'python', 'Python', re.I)
    if result:
        print("Match found")  # Output: Match found
    \`\`\`

#### Practice and Application
Understanding through practical implementation is key. Try creating and experimenting with different regular expressions on varying texts. Practice tasks can range from extracting emails from text, validating URLs, to more complex text manipulation and extraction tasks. Utilize online regular expression testers for immediate feedback and validation.

In this section, we have dived deep into the practical aspects and examples of using regular expressions in Python. Through various examples, the usage, application, and intricacies of regular expressions have been explored. With the foundational knowledge and practice acquired, you are well-equipped to start your journey of mastering regular expressions in Python for various practical applications and data manipulation tasks.
`;
export default activity3Content;