import React, {useEffect} from 'react';
import styles from './MainPage.module.css';
import ImageCarousel from "./ImageCarousel";

const MainPage = () => {
    useEffect(() => {
        // Override specific styles on the body element
        const originalStyle = {
            overflowX: document.body.style.overflowX,
            display: document.body.style.display,
            flexDirection: document.body.style.flexDirection,
            alignItems: document.body.style.alignItems,
            gap: document.body.style.gap,
        };

        // Specific styles for MainPage
        document.body.style.overflowX = 'hidden';
        document.body.style.display = 'block'; // Use 'block' instead of 'flex' for 100% width
        document.body.style.flexDirection = 'initial';
        document.body.style.alignItems = 'initial';
        document.body.style.gap = 'initial';

        // Reset styles when the component unmounts
        return () => {
            document.body.style.overflowX = originalStyle.overflowX;
            document.body.style.display = originalStyle.display;
            document.body.style.flexDirection = originalStyle.flexDirection;
            document.body.style.alignItems = originalStyle.alignItems;
            document.body.style.gap = originalStyle.gap;
        };
    }, []);

    return (
        <div className={styles.body}>
            <header className={styles.header}>
                <div className={styles.logoPlaceholder}></div>
                <nav className={styles.nav}>
                    <ul className={styles.navList}>
                        <li><a className={styles.navLink} href="/login">Login</a></li>
                        <li><a className={styles.navLink} href="/create-account">Create Account</a></li>
                        <li><a className={styles.navLink} href="/contact">Contact</a></li>
                    </ul>
                </nav>
            </header>
            <main className={styles.platformDescription}>
                <p>Learnix harnesses the advanced capabilities of OpenAI's GPT-4 to offer a transformative eLearning
                    experience.</p>
                {/* More informational content here */}
            </main>
            <ImageCarousel/> {/* Include the image carousel */}
            <div className={styles.threeColumns}>
                <div className={styles.column}>
                    <h3>Personalized Learning at Scale with GPT-4</h3>
                    <p>Incorporating OpenAI's GPT into eLearning revolutionizes education, offering personalized
                        learning experiences that adapt to individual progress. Its advanced natural language processing
                        enables real-time feedback and dynamic content generation, facilitating deeper engagement and
                        understanding. GPT's ability to analyze and respond to student input ensures that learning is
                        interactive, responsive, and tailored to each learner’s unique needs, significantly enhancing
                        the educational journey.</p>
                </div>
                <div className={styles.column}>
                    <h3>Revolutionizing Learning with AI-Enhanced Content and Feedback</h3>
                    <p>Leveraging GPT for content generation and feedback yields a diverse and ever-evolving educational
                        resource pool, ensuring content remains current and relevant. It promises precise, insightful
                        feedback on user submissions, enhancing learning by pinpointing strengths and areas for
                        improvement. This AI-driven approach not only streamlines content creation but also personalizes
                        the learning journey, making it a more effective and engaging experience for users across
                        various disciplines.</p>
                </div>
                <div className={styles.column}>
                    <h3>Diverse Learning: OpenAI's Custom Content Spectrum</h3>
                    <p>Utilizing OpenAI for course content development enables the creation of a vast array of topics
                        with unparalleled depth and breadth. This technology ensures that materials are not only rich
                        and varied but also tailored to current trends and educational standards. OpenAI's analytical
                        prowess allows for the curation of content that is both engaging and informative, catering to
                        diverse learning styles and making education more accessible and adaptable to individual learner
                        needs.</p>
                </div>
            </div>

            <div className={styles.headerContainer}>
                <img src="/f-left.png" alt="Decorative image"
                     className={`${styles.decorativeImage} ${styles.left}`}/>
                <h1>Our current course offerings</h1>
                <img src="/f-right.png" alt="Decorative image"
                     className={`${styles.decorativeImage} ${styles.right}`}/>
            </div>

            <div className={styles.courseContainer}>
                <div className={styles.course}>
                    <div className={styles.titleBar}>Python</div>
                    <img src="/python.png" alt="Python" className={styles.courseImage}/>
                </div>
                <div className={styles.course}>
                    <div className={styles.titleBar}>Cloud</div>
                    <img src="/AWS-CloudPractitioner-2020.png" alt="AWS Certified Cloud Practitioner" className={styles.courseImage}/>
                </div>
                <div className={styles.course}>
                    <div className={styles.titleBar}>React</div>
                    <img src="/logo512.png" alt="React" className={styles.courseImage}/>
                </div>
            </div>


            <footer className={styles.footer}>
                <p>© 2024 Learnix Education</p>
            </footer>
        </div>
    );
};

export default MainPage;