const activity1Content = `
# Introduction

The following short-answer questions are not typically representative of the format you will encounter on the AWS Certified
Cloud Practitioner exam, which primarily features multiple-choice and multiple-response questions, they are excellent for 
deepening your understanding and testing your knowledge of key AWS concepts. Engaging with these types of questions can enhance 
your ability to think critically about AWS services and principles, aiding in the development of a more comprehensive and 
practical understanding of the subject matter, which is beneficial for both the exam and real-world applications.

***Your answers will be assessed by the GPT model.***
`;

export default activity1Content;
