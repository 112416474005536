import React, {useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import './global_theme.css';

function AWSCCPOverview() {
    const [isBackLoading, setBackLoading] = useState(false);
    const navigate = useNavigate();

    const handleBackClick = async  () => {
        setBackLoading(true);
        await new Promise(resolve => setTimeout(resolve, 2000));
        setBackLoading(false);
        navigate("/courses-overview");
    }

    return (
        <div className="course-overview">
            <div className="header-spacer"></div>
            <div className="header-overview">AWS Certified Cloud Practitioner Practice</div>
            {/*Individual lesson links*/}
            <ul>
                <dt><Link to="/aws-practice">AWS CCP Practice Questions</Link></dt>
            </ul>
            <button className="other-btn" onClick={handleBackClick}>
                {isBackLoading ? <div className="spinner"></div> : 'Back'}
            </button>
        </div>
    )
}
export default AWSCCPOverview;