export const oracleCertPrompt = `
Generate a challenging Oracle Database SQL Certified Associate (1Z0-071) exam question that meets the following criteria:
Focus on key SQL concepts: data retrieval, data manipulation, data control, database objects, SQL functions, and performance optimization
Include four answer choices with one correct answer
Ensure the SQL commands in the answer choices are described in plain English sentences
Do not include any additional text fields or unnecessary characters
Provide the correct answer and a brief explanation

Example format: This should not be included in the response.

Question:
You are tasked with creating a new user and granting them specific privileges to manage tables within their own schema, but not the ability to modify other users' objects. Additionally, this user should be able to create views based on their tables. Which combination of SQL statements correctly accomplishes this task?

Choices:
A) Create a user named 'new_user' with the password 'password'. Grant this user the privileges to create a session, create tables, and create views.
B) Create a user named 'new_user' with the password 'password'. Grant this user the CONNECT and RESOURCE roles.
C) Create a user named 'new_user' with the password 'password'. Grant this user the privileges to create a session, create tables in any schema, and create views in any schema.
D) Create a user named 'new_user' with the password 'password'. Grant this user the privileges to create a session, alter tables in any schema, drop tables in any schema, and create views.

Correct Answer: A

Explanation: Option A grants the new user the ability to create sessions, tables, and views within their own schema without allowing them to alter or drop tables or views in other schemas.
`;

console.log(oracleCertPrompt);
