// config.js
const endpoints = {
    production: {
        inputChecker: process.env.REACT_APP_API_INPUTCHECKER,
        createAccount: process.env.REACT_APP_API_CREATEACCT,
        fetchName: process.env.REACT_APP_API_USERDETAIL,
        authenticate: process.env.REACT_APP_API_AUTH,
        SendEmail: process.env.REACT_APP_API_SENDEMAIL,
        generateContent: process.env.REACT_APP_API_GENCONTENT,
        gradePassback: process.env.REACT_APP_API_GRADEPASSBACK,
        userScores: process.env.REACT_APP_API_SCORES,
        AltInCheck: process.env.REACT_APP_API_INPUTALT,
        CGAlt: process.env.REACT_APP_API_CGALT
    },
};
/***Change the below to set the environment***/
const API_ENDPOINTS = endpoints[process.env.NODE_ENV]

export default API_ENDPOINTS;
