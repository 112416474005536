const activity2Content = `
### Variables in Python

Variables are the building blocks of any program, acting as containers to store data values. They make programs flexible and efficient by allowing you to manage data dynamically. Let's delve deeper into variables in Python:

#### Variable Assignment

In Python, assigning a value to a variable is straightforward. Use the assignment operator \`=\` to do so.

\`\`\`python
x = 10
name = "Alice"
\`\`\`

Here, \`x\` stores an integer \`10\`, and \`name\` stores a string \`"Alice"\`.

**Multiple Assignment**: Python also allows you to assign multiple variables in a single line, like this:

\`\`\`python
a, b, c = 5, 3.2, "Hello"
\`\`\`

#### Dynamic Typing

Python employs dynamic typing, making it unnecessary to explicitly declare the data type of a variable. The type is inferred at runtime based on the assigned value.

\`\`\`python
age = 25  # Integer
name = "Bob"  # String
\`\`\`

**Type Checking**: You can check the type of a variable using the \`type()\` function:

\`\`\`python
print(type(age))  # Output: <class 'int'>
print(type(name))  # Output: <class 'str'>
\`\`\`

#### Variable Names (Identifiers)

Variables must follow certain naming rules:

- They must start with a letter (a-z, A-Z) or an underscore (_).
- They can contain letters, numbers (0-9), and underscores.
- Names are case-sensitive: \`myVar\` and \`myvar\` are different.

**Reserved Words**: Avoid using Python's reserved words (like \`if\`, \`else\`, \`while\`, etc.) as variable names.

#### Reassigning Variables

Variables can be updated or reassigned easily.

\`\`\`python
x = 5  # x stores 5
x = 7  # x now stores 7
\`\`\`

**Chaining Assignment**: You can chain assignments to assign a single value to multiple variables:

\`\`\`python
x = y = z = 0  # x, y, and z all store the value 0
\`\`\`

#### Variable Scope

The accessibility of a variable depends on where it is declared:

- **Local Variables**: Defined inside a function and only accessible within that function.
- **Global Variables**: Defined outside any function and accessible throughout the code.

\`\`\`python
def my_function():
    local_var = "I'm local!"
  
global_var = "I'm global!"

# local_var is not accessible here
# global_var is accessible here
\`\`\`

#### Best Practices

- Use **meaningful names**: \`student_count\` is better than \`s_c\`.
- **Lowercase with underscores**: Python's naming convention typically uses lowercase letters with underscores for variable names, like \`student_name\`.
- **Comments**: When the variable's purpose isn't obvious, a comment can provide clarification.

#### Conclusion

Understanding variables is crucial for Python programming, as they enable you to write dynamic, flexible, and efficient code. By mastering the different aspects of variables—from naming conventions and type to scope and best practices—you'll be well-equipped to develop robust Python applications.
`;

export default activity2Content;
