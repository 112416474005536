const activity1Content = `
# Introduction to Variables, Expressions, and Statements in Python

In the world of Python programming, understanding the concepts of variables, expressions, and statements is fundamental.
These elements serve as the building blocks for writing code and performing computations. Let's break down what each of these means:

1. **Variables:**
   Variables are like containers that hold data.
   They allow you to store and manipulate values, such as numbers, text, or other types of data.
   In Python, you don't need to declare the data type explicitly; Python dynamically assigns the data type based on the value assigned to the variable.
2. **Expressions:**
   Expressions are combinations of variables, values, and operators that Python can evaluate to produce a result.
   For example, x + y is an expression that adds the values stored in variables x and y.
   Expressions can be simple or complex, involving mathematical operations, comparisons, and more.
3. **Statements:**
   Statements are lines of code that instruct Python to perform specific actions or operations.
   Assigning a value to a variable, printing a message, or controlling program flow with conditionals and loops are all examples of statements.
   Python statements are typically written one per line and executed sequentially.
   Understanding how to use variables to store data, create expressions to perform computations, and write statements to control program flow is the foundation of Python programming. As you delve deeper into Python, you'll discover the versatility and power of these concepts in building sophisticated applications.
   Now, let's explore each of these elements in more detail to get a better grasp of how they work in Python.
`;

export default activity1Content;
