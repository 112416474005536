import React, { useRef, useEffect } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import styles from './SwiperComponent.module.css';
// Import Swiper styles
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'

// import Swiper core and required modules
import SwiperCore, {
    EffectCoverflow,
    Pagination,
    Navigation
} from 'swiper';

// install Swiper modules
SwiperCore.use([EffectCoverflow, Pagination]);

const ImageCarousel = () => {

    return (
        <Swiper
            style={{ marginLeft: '12px', marginRight: '12px' }}
            effect={'coverflow'}
            navigation={true}
            modules={[Navigation, Pagination, EffectCoverflow]}
            className={styles.swiperSlideShadowLeft}
            grabCursor={true}
            centeredSlides={true}
            slidesPerView={3} // Adjusts the number of slides visible at once
            spaceBetween={30} // Adjusts the space between slides
            coverflowEffect={{
                rotate: 50,
                stretch: -50,
                depth: 20,
                modifier: 1,
                slideShadows: false,
            }}
            loop={true} // Enable looping of slides
            //pagination={true}
            breakpoints={{
                // when window width is >= 320px
                320: {
                    slidesPerView: 1,
                    spaceBetween: 10
                },
                // when window width is >= 480px
                480: {
                    slidesPerView: 2,
                    spaceBetween: 20
                },
                // when window width is >= 640px
                640: {
                    slidesPerView: 3,
                    spaceBetween: 30
                }
            }}
        >
            {/* ... slides ... */}
            <SwiperSlide className={styles.swiperSlide}>
                <img
                    src="/Image4_GPT.png"
                    alt="Futuristic Classroom"
                    style={{ width: '100%', height: '75%', objectFit: "cover"}}
                />
                <div className={styles.slideText}>
                    <h2>Enhanced Education: Integrated GPT Technology in eLearning</h2>
                    <p>GPT's advanced AI transforms traditional eLearning, offering personalized, interactive educational experiences with real-time feedback and content adaptation.</p>
                </div>
            </SwiperSlide>
            <SwiperSlide className={styles.swiperSlide}>
                <img
                    src="/Image3_GPT.png"
                    alt="GPT creating content for a Python programming course"
                    style={{ width: '100%', height: '75%', objectFit: "cover" }}
                />
                <div className={styles.slideText}>
                    <h2>AI-Powered Learning Dynamics</h2>
                    <p>GPT-powered tools that dynamically generate diverse content and provide tailored feedback.</p>
                </div>
            </SwiperSlide>
            <SwiperSlide className={styles.swiperSlide}>
                <img
                    src="/Image2_GPT.png"
                    alt="Course content creation through OpenAI"
                    style={{ width: '100%', height: '75%', objectFit: "cover" }}
                />
                <div className={styles.slideText}>
                    <h2>AI-Curated Learning: Diverse and Current</h2>
                    <p>Experience the cutting-edge of eLearning with OpenAI’s curated courses, designed to deliver rich, up-to-date content that caters to all learning styles.</p>
                </div>
            </SwiperSlide>
            {/* ... Add more slides later */}
        </Swiper>
    );
};

export default ImageCarousel;