const activity3Content = `
#### Lists with Examples:
- **Defining and Accessing Lists:**
  \`\`\`python
  fruits = ["apple", "banana", "cherry"]
  print(fruits[1])  # Output: banana
  \`\`\`
  
- **List Methods:**
  \`\`\`python
  fruits.append("orange")
  fruits.remove("banana")
  \`\`\`
  
- **List Comprehension:**
  \`\`\`python
  squares = [x**2 for x in range(10)]
  \`\`\`

#### Dictionaries with Examples:
- **Defining and Accessing Dictionaries:**
  \`\`\`python
  person = {"name": "John", "age": 30, "city": "New York"}
  print(person["name"])  # Output: John
  \`\`\`

- **Dictionary Methods:**
  \`\`\`python
  person.keys()  # Output: dict_keys(['name', 'age', 'city'])
  person.values()  # Output: dict_values(['John', 30, 'New York'])
  \`\`\`

- **Dictionary Comprehension:**
  \`\`\`python
  squares = {x: x**2 for x in range(6)}
  \`\`\`

#### Tuples with Examples:
- **Defining and Accessing Tuples:**
  \`\`\`python
  coordinates = (4, 5)
  print(coordinates[0])  # Output: 4
  \`\`\`

- **Tuple Methods:**
  Tuples have limited methods due to their immutability, but you can still perform operations like \`count\` and \`index\`:
  \`\`\`python
  my_tuple = (1, 2, 3, 2, 2)
  print(my_tuple.count(2))  # Output: 3
  \`\`\`

- **Unpacking Tuples:**
  \`\`\`python
  x, y = coordinates
  \`\`\`

#### Use Cases and Examples:
Understanding the use-cases for each of these data structures is crucial:
- **Lists:** Ideal for ordered collections of items, like a list of numbers or strings.
- **Dictionaries:** Perfect for storing and quickly retrieving data through unique keys.
- **Tuples:** Useful when you need an ordered collection that should not change (immutable).

#### Exercises and Practice:
Engage with interactive examples and exercises to reinforce learning. Implement different operations on lists, dictionaries, and tuples, explore their methods, and understand the best practices in using each.

Understanding and mastering Lists, Dictionaries, and Tuples in Python will significantly enhance your data manipulation and programming skills, providing you with robust tools to solve a wide range of programming challenges. Each data structure has unique properties and methods that make them suitable for different tasks, and knowing when to use which is a vital skill for every Python programmer. Practice and exploration are key to mastering these fundamental Python data structures.
`;

export default activity3Content;