const activity6Content = `
### Statements in Python

Statements in Python serve as the actionable units of code that execute specific tasks. They structure the logic of your programs and control how your code behaves. Below are key features and types of statements in Python:

#### Types of Statements

Python incorporates a wide range of statement types:

- **Assignment Statements**
- **Control Flow Statements**
- **Function Call Statements**
- **Import Statements**
- **Print Statements**

#### Assignment Statements

Assignment statements allocate values to variables using the assignment operator \`=\`.

\`\`\`python
x = 5
name = "Alice"
\`\`\`

**Multiple Assignments**: Python also allows multiple assignments in one line.

\`\`\`python
a, b, c = 1, 2, 3  # Assigns 1 to a, 2 to b, and 3 to c
\`\`\`

#### Print Statements

The \`print()\` function prints data to the console—useful for debugging and providing program status updates.

\`\`\`python
print("Hello, World!")  # Outputs "Hello, World!" to the console
\`\`\`

**Formatted Printing**: You can format print statements to include variable values:

\`\`\`python
age = 25
print(f"Age is {age}")  # Outputs "Age is 25"
\`\`\`

#### Control Flow Statements

Control flow statements regulate the execution order of your code.

- **\`if\`, \`elif\`, \`else\`**: Conditional statements.
- **\`for\`, \`while\`**: Looping statements.
- **\`break\`, \`continue\`**: Modify loop behavior.
- **\`return\`**: Exit a function and return a value.

\`\`\`python
if x > 5:
    print("x is greater than 5")
else:
    print("x is not greater than 5")
\`\`\`

#### Indentation

Proper indentation is non-negotiable in Python, as it defines code blocks. The most commonly used indentation is 4 spaces.

\`\`\`python
for i in range(3):
    print(i)  # Indented by 4 spaces
\`\`\`

#### Colon

The colon \`:\` often follows control flow statements like \`if\`, \`for\`, and \`while\`, signaling the start of an indented code block.

\`\`\`python
while x > 0:
    x -= 1  # Code block starts here
\`\`\`

#### Comments

Comments provide in-code documentation and explanations. They begin with the \`#\` symbol.

\`\`\`python
# This line is a comment and will not be executed
\`\`\`

#### Function Call Statements

Functions encapsulate specific tasks and can be called upon with or without arguments.

\`\`\`python
result = add(3, 4)  # Calls the function 'add'
\`\`\`

#### Multiple Statements per Line

While Python permits multiple statements on a single line separated by a semicolon \`;\`, this is generally discouraged due to readability concerns.

\`\`\`python
x = 10; print(x)  # Not recommended
\`\`\`

#### Conclusion

Statements act as the cornerstone of Python programming. They execute actions, facilitate decision-making, and control code flow. A solid understanding of how to use various statement types is vital for effective Python programming.
`
export default activity6Content;