import React from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import Login from './Login';
import CreateAccount from "./CreateAccount";
import Overview from "./Overview";
import Contact from "./Contact";
import PythonOverview from "./Python-Overview";
import EssaysOverview from "./Essays-Overview";
import VarsExpStat from "./python/VarsExpStat";
import Strings from "./python/Strings";
import RegEx from "./python/RegEx";
import Iteration from "./python/Iteration";
import Functions from "./python/Functions";
import Files from "./python/Files";
import Containers from "./python/Containers";
import ConditionalEx from "./python/ConditionalEx";
import ScoringOverview from "./Scoring-Overview";
import MainPage from "./MainPage";
import AWSCCPOverview from "./AWS-CCP-Overview";
import AwsPractice from "./aws-ccp/aws-practice";
import OracleOverview from "./Oracle-Exam-Overview";
import OraclePractice from "./oracle/oracle-practice";

function App() {
    return (
        /*
        * Routes determine where a page will be directed upon the navigation() call
        * */
        <Router>
            <Routes>
                <Route path="/login" element={<Login />} />
                {/* ... any other routes */}
                <Route path="/create-account" element={<CreateAccount />} />
                <Route path="/courses-overview" element={<ProtectedWrapper><Overview /></ProtectedWrapper>} />
                <Route path="/python-overview" element={<ProtectedWrapper><PythonOverview /></ProtectedWrapper>} />
                <Route path="/scoring-overview" element={<ProtectedWrapper><ScoringOverview /></ProtectedWrapper>} />

                <Route path="/py-lesson1" element={<ProtectedWrapper><VarsExpStat /></ProtectedWrapper>} />
                <Route path="/py-lesson2" element={<ProtectedWrapper><ConditionalEx /></ProtectedWrapper>} />
                <Route path="/py-lesson3" element={<ProtectedWrapper><Functions /></ProtectedWrapper>} />
                <Route path="/py-lesson4" element={<ProtectedWrapper><Iteration /></ProtectedWrapper>} />
                <Route path="/py-lesson5" element={<ProtectedWrapper><Strings /></ProtectedWrapper>} />
                <Route path="/py-lesson6" element={<ProtectedWrapper><Files /></ProtectedWrapper>} />
                <Route path="/py-lesson7" element={<ProtectedWrapper><Containers /></ProtectedWrapper>} />
                <Route path="/py-lesson8" element={<ProtectedWrapper><RegEx /></ProtectedWrapper>} />
                <Route path="/aws-practice" element={<ProtectedWrapper><AwsPractice /></ProtectedWrapper>} />
                <Route path="/oracle-practice" element={<ProtectedWrapper><OraclePractice /></ProtectedWrapper>} />

                <Route path="/essays-overview" element={<ProtectedWrapper><EssaysOverview /></ProtectedWrapper>} />
                <Route path="/awsccp-overview" element={<ProtectedWrapper><AWSCCPOverview /></ProtectedWrapper>} />
                <Route path="/oracle-overview" element={<ProtectedWrapper><OracleOverview /></ProtectedWrapper>} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/" element={<MainPage />} />
                {/* <Route path="/" element={<NavigateToLogin />} /> */}
            </Routes>
        </Router>
    );
}
/*
* ProtectedWrapper() will prevent direct linking to pages without first logging in
* */
function ProtectedWrapper({ children }) {
    let navigate = useNavigate();
    const isAuthenticated = Boolean(localStorage.getItem('authToken'));

    React.useEffect(() => {
        if (!isAuthenticated) {
            navigate('/login');
        }
    }, [isAuthenticated, navigate]);

    if (!isAuthenticated) {
        return null;
    }

    return children;
}

function NavigateToLogin() {
    let navigate = useNavigate();
    React.useEffect(() => {
        navigate('/login');
    }, [navigate]);

    return null;
}

export default App;