import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill styles
import './global_theme.css';
import { useNavigate } from "react-router-dom";

function EssaysOverview() {
    const navigate = useNavigate();
    const [isBackLoading, setBackLoading] = useState(false);
    const [isSubmitLoading, setSubmitLoading] = useState(false);
    const [editorContent, setEditorContent] = useState('<p>Start typing!</p>');

    const handleSubmitBack = async () => {
        setBackLoading(true);
        await new Promise(resolve => setTimeout(resolve, 2000));
        setBackLoading(false);
        navigate("/courses-overview");
    }

    const handleSubmitEssay = async () => {
        // Your submit logic here
    }

    const modules = {
        toolbar: [
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            ['bold', 'italic', 'underline', 'strike'],
            [{'list': 'ordered'}, {'list': 'bullet'}],
            [{'indent': '-1'}, {'indent': '+1'}],
            [{'align': []}],
            ['link', 'image'],
            ['clean'],
            ['blockquote', 'code-block'],
            [{ 'color': [] }, { 'background': [] }],
            ['formula'],
        ],
    };

    return (
        <div className="main-content">
            <ReactQuill
                theme="snow"
                value={editorContent}
                onChange={setEditorContent}
                modules={modules}
                style={{ height: '400px', width: '800px', marginBottom: '50px' }}
            />
            <button className="other-btn" onClick={handleSubmitBack}>
                {isBackLoading ? <div className="spinner"></div> : 'Back'}
            </button>
            <button className="other-btn" onClick={handleSubmitEssay}>
                {isSubmitLoading ? <div className="spinner"></div> : 'Submit'}
            </button>
        </div>
    );
}

export default EssaysOverview;